@import "./base.css";

.d-none {
    display: none !important;
}

.w-100 {
    width: 100% !important;
}

.with-return-city {
    .col-span-2 {
        grid-column: span 1 / span 1 !important;
    }
}

.was-validated *:invalid {
    border-color: rgb(255 57 0 / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.was-validated *:invalid::placeholder {
    color: rgb(255 57 0);
}

.text-container h2 {
    font-size: 24px;
}

.text-container .text-block {
    margin-bottom: 1rem;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    max-width: 800px;
    margin: 1.75rem auto;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
    padding: 25px;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.loading-logo {
    width: 70%;
}

.loading-column {
    flex: 25%;
    max-width: 25%;
}

.loading-column > div {
    display: flex;
    justify-content: center;
}

.loading-row {
    flex-wrap: wrap;
    justify-content: center;
}

.flex-container-space-between {
    display: flex;
    justify-content: space-between;
}

.cityModal,
.returnCityModal {
    top: 40% !important;
}
.cityModal .modal-dialog,
.returnCityModal .modal-dialog {
    width: fit-content;
}

.menu-dropdown {
    position: relative;
    display: inline-block;
}

.menu-dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    padding: 12px 0;
    z-index: 1;
    max-height: 70vh;
    overflow-y: scroll;
}

.menu-dropdown-content > a {
    background: #fff;
    display: block;
}

.menu-dropdown:hover > .menu-dropdown-content {
    display: flex;
    flex-direction: column;
}

.dropdown input, .dropdown ul.submenu {
    display: none;
}

/*position the label*/
.dropdown label {
    position: relative;
    display: block;
    cursor: pointer;
}

/*show the submenu when input is checked*/
.dropdown input:checked~ul.submenu {
    display: block;
}

.text-uppercase {
    text-transform: uppercase;
}

.footer-custom-pages {
    max-height: 500px;
    overflow-y: scroll;
}

.cars-widget {
    width: 80vw;
    overflow: visible;
}

figure.image {
    display: flex;
    justify-content: center;
}

figure.image.image-style-side {
    display: flex;
    justify-content: right;
}

@media (max-width: 768px) {
    .loading-column {
        flex: 50%;
    }
    .pagination-page-box {
        display: none !important;
    }
    .car-image {
        right: 0 !important;
    }
    .cheapest {
        font-size: 8px !important;
        right: 0 !important;
    }
}